import React from 'react';
import {ThemeProvider as MUIThemeProvider} from '@material-ui/styles';
import {createMuiTheme} from '@material-ui/core/styles';

const theme = createMuiTheme({
  breakpoints: {
    values: {
      xs: 0,
      sm: 664,
      md: 960,
      lg: 1280,
      xl: 1920,
    },
  },
  palette: {
    type: 'dark',
  },
  overrides: {
    MuiPaper: {
      root: {
        padding: 8,
        display: 'flex',
        overflow: 'auto',
        flexDirection: 'column',
      },
    },
    MuiTypography: {
      body1: {
        // fontSize: '1rem',
      },
      // body2: {
      //   fontSize: '1rem',
      // },
    },
  },
});

interface Props {
  children: React.ReactNode;
}

export default function ThemeProvider({children}: Props) {
  return <MUIThemeProvider theme={theme}>{children}</MUIThemeProvider>;
}
