import React, {useState, useContext, useEffect, useReducer} from 'react';
import {useQuery} from '@shopify/react-graphql';
import {loader} from 'graphql.macro';
import {fetchAtOr, fetchByPathOr} from 'packages/@shopify/maybe';
import {AuthCheckQueryDataResponse} from './graphql/AuthCheckQuery/AuthCheckQueryData';
import {AccountData} from 'types/account';

// Reducer
const initialState = {
  accountData: {
    administrator: false,
    bonusAvailable: 0,
    google_email: '',
    google_user_id: '',
    inboxAll: 0,
    inboxNew: 0,
    servers: [],
    time: 0,
    username: '',
    patron: false,
    userid: 0,
    premium: false,
    premium_until: 0,
    credits: 0,
  },
};
export type ReducerActionTypes = 'SET_ACCOUNT_DATA';

export interface AccountState {
  accountData: AccountData;
}

export interface KeyValuePairs {
  [key: string]: string | number | boolean;
}

export type ReducerPayload = KeyValuePairs | AccountData;

export interface ReducerAction {
  payload?: ReducerPayload;
  type: ReducerActionTypes;
}

export type Reducer = (
  state: AccountState,
  action: ReducerAction,
) => AccountState;

const reducer: Reducer = (state, action) => {
  const {payload} = action;
  switch (action.type) {
    case 'SET_ACCOUNT_DATA':
      return {...state, accountData: {...state.accountData, ...payload}};
    default:
      return {...state};
  }
};

// Context
interface AuthContext {
  authenticated: boolean;
  loading: boolean;
  accountData: AccountData;
  refetchAccountData: () => void;
}

const AuthContext = React.createContext<AuthContext>({
  authenticated: false,
  loading: false,
  accountData: initialState.accountData,
  refetchAccountData: () => {},
});

export function useAuthContext() {
  return useContext(AuthContext);
}

interface ProviderProps {
  children: React.ReactNode;
}

export function AuthProvider({children}: ProviderProps) {
  const [authenticated, setAuthenticated] = useState(false);
  const [loading, setLoading] = useState(true);
  const [state, dispatch] = useReducer(reducer, initialState);

  const query = loader('./graphql/AuthCheckQuery/AuthCheckQuery.graphql');
  const {data, refetch} = useQuery<AuthCheckQueryDataResponse>(query);
  useEffect(() => {
    if (fetchAtOr(data, 'AuthCheck', false)) {
      const error = fetchByPathOr(
        data,
        ['AuthCheck', 'errors', 0, 'message'],
        '',
      );
      if (!error) {
        setAuthenticated(true);
      } else {
        window.location.href = '/login';
      }
      const newAccountData = fetchByPathOr(
        data,
        ['AuthCheck', 'account'],
        false,
      );
      if (newAccountData) {
        dispatch({type: 'SET_ACCOUNT_DATA', payload: newAccountData});
      }
      setLoading(false);
    }
  }, [data]);

  return (
    <AuthContext.Provider
      value={{
        authenticated,
        loading,
        accountData: state.accountData,
        refetchAccountData: refetch,
      }}
    >
      {children}
    </AuthContext.Provider>
  );
}
