import * as React from 'react';
import 'govicons/css/govicons.min.css';

export interface Props {
  name: string;
  color?: string;
  size?: number;
  style?: React.CSSProperties;
  className?: String;
}

function GovIcon({name, color, size, style, className}: Props) {
  const iconSize = size || 'inherit';
  const iconColor = color;
  const iconStyle = style || {};
  return (
    <i
      className={`gi gi-${name} ${className}`}
      style={{color: iconColor, fontSize: iconSize, ...iconStyle}}
    />
  );
}

export default GovIcon;
