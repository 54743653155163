import React from 'react';
import {BrowserRouter as Router, Route} from 'react-router-dom';
import TestComponent from '../../TestComponent';
import {asyncComponent} from 'components';

export default function Routes() {
  const asyncLogin = asyncComponent(() =>
    import('sections/Public/sections/Login'),
  );
  const asyncSignUp = asyncComponent(() =>
    import('sections/Public/sections/SignUp'),
  );
  const asyncPortal = asyncComponent(() => import('sections/Portal'));

  const asyncGame = asyncComponent(() => import('sections/Game'));

  return (
    <Router>
      <Route path="/" exact component={asyncLogin} />
      <Route path="/login" exact component={asyncLogin} />
      <Route path="/signup" exact component={asyncSignUp} />
      <Route path="/test" exact component={TestComponent} />
      <Route path="/portal" component={asyncPortal} />
      <Route path="/game/:serverId" component={asyncGame} />
      <Route path="/game/:serverId/multi/:multiId" component={asyncGame} />
    </Router>
  );
}
