import React, {useContext} from 'react';
import {ApolloClient} from 'apollo-client';
import {createHttpLink} from 'apollo-link-http';
import {setContext} from 'apollo-link-context';
import {InMemoryCache} from 'apollo-cache-inmemory';

const httpLink = createHttpLink({
  uri: 'https://shop.earthempires.com/api/graphql',
});

const middlewareLink = setContext(() => ({
  headers: {
    'X-Shopify-Storefront-Access-Token':
      process.env.REACT_APP_SHOPIFY_STOREFRONT_ACCESS_TOKEN,
  },
}));

const shopifyApolloClient = new ApolloClient({
  link: middlewareLink.concat(httpLink),
  cache: new InMemoryCache(),
});

// Context
interface ShopifyApolloContext {
  shopifyApolloClient: ApolloClient<any>;
}

const AuthContext = React.createContext<ShopifyApolloContext>({
  shopifyApolloClient,
});

export function useShopifyApollo() {
  return useContext(AuthContext);
}

interface ProviderProps {
  children: React.ReactNode;
}

export function ShopifyApolloProvider({children}: ProviderProps) {
  return (
    <AuthContext.Provider value={{shopifyApolloClient}}>
      {children}
    </AuthContext.Provider>
  );
}
