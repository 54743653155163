import React from 'react';
import {useI18n} from '@shopify/react-i18n';
import {getTranslationDictionary} from 'locales';
import en from 'locales/en.json';

function translations(locale: string) {
  return getTranslationDictionary(locale);
}

interface Props {
  children?: React.ReactNode;
}

function WithTranslations({children}: Props) {
  const [, ShareTranslations] = useI18n({
    id: 'Root',
    fallback: en,
    translations,
  });

  return <ShareTranslations>{children}</ShareTranslations>;
}

export default React.memo(WithTranslations);
