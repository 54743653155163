import React from 'react';
import clsx from 'clsx';
import {makeStyles} from '@material-ui/core/styles';

const useStyles = makeStyles((theme) => ({
  greenText: {
    color: `rgb(153, 255, 187)`,
  },
  dark: {
    color: `rgb(96, 183, 125)`,
  },
}));

interface Props {
  children: React.ReactNode;
  dark?: boolean;
}

export default function GreenText({children, dark = false}: Props) {
  const classes = useStyles();
  return (
    <span className={clsx(classes.greenText, dark && classes.dark)}>
      {children}
    </span>
  );
}
