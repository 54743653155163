import React from 'react';
import CircularProgress from '@material-ui/core/CircularProgress';
import Typography from '@material-ui/core/Typography';
import {makeStyles} from '@material-ui/core/styles';
import {useI18n} from '@shopify/react-i18n';

const sizeNumbers = {
  small: 20,
  medium: 40,
  large: 80,
};

const useStyles = makeStyles((theme) => ({
  loadingText: {
    marginTop: theme.spacing(2),
  },
}));

interface Props {
  size?: 'small' | 'medium' | 'large';
}

export default function LoadingSpinner({size = 'medium'}: Props) {
  const [I18n] = useI18n();
  const classes = useStyles();
  const sizeNumber = sizeNumbers[size];
  return (
    <div>
      <CircularProgress size={sizeNumber} />
      <br />
      <Typography variant="body2" component="p" className={classes.loadingText}>
        {I18n.translate('LoadingSpinner.loading')}
      </Typography>
    </div>
  );
}
