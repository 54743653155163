import React from 'react';
import {makeStyles} from '@material-ui/core/styles';

const useStyles = makeStyles((theme) => ({
  redText: {
    color: theme.palette.error.main,
  },
}));

interface Props {
  children: React.ReactNode;
}

export default function RedText({children}: Props) {
  const classes = useStyles();
  return <span className={classes.redText}>{children}</span>;
}
