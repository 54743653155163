import React from 'react';
import {useQuery} from '@shopify/react-graphql';
import {fetchByPathOr} from 'packages/@shopify/maybe';
import {loader} from 'graphql.macro';

interface Result {
  repo: {
    id: number;
    name: string;
    description: string;
    html_url: string;
  };
}

export default function TestComponent() {
  const query = loader('./testQuery.graphql');

  const {data, loading} = useQuery<Result>(query, {
    variables: {
      name: 'recipes',
      user: 'dmcvittie',
    },
  });
  console.log('loading', loading);
  console.log('data', data);
  console.log(
    'data with maybe',
    fetchByPathOr<Result['repo']['name']>(data, ['repo'], ''),
  );
  const loadingMarkup = loading && <div>Loading!</div>;
  return <div>{loadingMarkup}Component!</div>;
}
