import moment from 'moment-timezone';

export function FormatDate24Hrs(timestamp: number) {
  return moment(timestamp, 'x')
    .tz('GMT')
    .format('HH:mm:ss MMM D, YYYY');
}

export function FormatDate24HrsSimple(timestamp: number) {
  return moment(timestamp, 'x')
    .tz('GMT')
    .format('MMM D HH:mm');
}

export function MakeSecondsReadable(seconds: number) {
  if (seconds < 60) {
    return `${seconds} seconds`;
  } else if (seconds < 3600) {
    return `${Math.floor(seconds / 60)} minutes`;
  } else if (seconds < 86400) {
    return `${Math.floor(seconds / 360) / 10} hours`;
  }
  return `${Math.floor(seconds / 8640) / 10} days`;
}
