// import {Fn, Maybe, NOTHING, Nothing} from './types';
export type Nothing = undefined | null;
export type Maybe<T> = T | Nothing;
export const NOTHING: Nothing = undefined;
export type Fn<T, U = T> = (item: T) => U;

// @ts-ignore
// export {Fn, Maybe, NOTHING, Nothing};

export function fetchAt<T>(obj: Maybe<any>, key: string | number): Maybe<T> {
  if (just(obj) && just<T>(obj[key])) {
    return obj[key];
  } else {
    return NOTHING;
  }
}

export function fetchAtOr<T>(obj: Maybe<any>, key: string | number, or: T): T {
  if (just(obj) && just<T>(obj[key])) {
    return obj[key];
  } else {
    return or;
  }
}

export function unwrap<T>(obj: Maybe<T>): T | never {
  if (just<T>(obj)) {
    return obj as T;
  } else {
    throw Error(`Attempted to unwrap an empty option: ${obj as unknown}`);
  }
}

export function unwrapOr<T>(obj: Maybe<T>, or: T): T {
  if (just<T>(obj)) {
    return obj as T;
  } else {
    return or;
  }
}

export function fMap<T, U = T>(
  array: ReadonlyArray<Maybe<T>>,
  fn: Fn<T, U>,
): Maybe<U>[];
export function fMap<T, U = T>(
  array: ReadonlyArray<Maybe<T>>,
  fn: Fn<T, U>,
): ReadonlyArray<Maybe<U>> {
  return array.map((item: Maybe<T>) => unwrapWith<T, U>(item, fn));
}

export function mapMaybes<T, U = unknown>(
  array: Maybe<ReadonlyArray<Maybe<T>>>,
  fn: Fn<T, Maybe<U>>,
): ReadonlyArray<U>;
export function mapMaybes<T, U = unknown>(
  array: Maybe<ReadonlyArray<Maybe<T>>>,
  fn: Fn<T, U>,
): ReadonlyArray<U> {
  const mapped: Maybe<U>[] = fMap<T, U>(unwrapOr(array, []), fn);
  return mapped.filter(just) as ReadonlyArray<U>;
}

export function unwrapWith<T, Out>(obj: Maybe<T>, fn: Fn<T, Out>): Maybe<Out> {
  if (just<T>(obj)) {
    return fn(unwrap<T>(obj));
  }
  return NOTHING;
}

export function unwrapWithOr<T, U>(obj: Maybe<T>, fn: Fn<T, U>, or: U): U {
  if (just<T>(obj)) {
    return unwrapOr<U>(unwrapWith<T, U>(obj, fn), or);
  }
  return or;
}

export function just<T>(obj: Maybe<T>): boolean {
  if (obj === undefined || obj === null) {
    return false;
  }
  return true;
}

export function fetchByPathOr<T>(
  obj: Maybe<any>,
  path: ReadonlyArray<string | number>,
  or: T,
): T {
  if (path.length === 0 || !just<T>(obj)) {
    return unwrapOr<T>(obj, or);
  }
  return fetchByPathOr<T>(obj[path[0]], path.slice(1), or);
}

export function apply<T>(
  obj: Maybe<any>,
  applicable: string,
  params: ReadonlyArray<unknown>,
): Maybe<T> {
  if (just(obj) && just(obj[applicable])) {
    return obj[applicable](...params);
  } else {
    return NOTHING;
  }
}
