import React from 'react';
import {makeStyles} from '@material-ui/core/styles';
import TextField, {TextFieldProps} from '@material-ui/core/TextField';

const useStyles = makeStyles((theme) => ({
  textField: {
    marginLeft: theme.spacing(1),
    marginRight: theme.spacing(1),
    width: 50,
    '& ::-webkit-inner-spin-button': {
      '-webkit-appearance': 'none',
      margin: 0,
    },
    '& ::-webkit-outer-spin-button': {
      '-webkit-appearance': 'none',
      margin: 0,
    },
  },
}));

interface InternalProps {
  formStateOnChange?: any;
  formStateError?: string;
}

type Props = TextFieldProps & InternalProps;

export default function InlineTextField(props: Props) {
  const classes = useStyles();
  const {formStateOnChange, formStateError, ...textFiedlProps} = props;
  let onChange;
  if (typeof formStateOnChange === 'function') {
    onChange = (
      event: React.ChangeEvent<
        HTMLInputElement | HTMLTextAreaElement | HTMLSelectElement
      >,
    ) => formStateOnChange && formStateOnChange(event.target.value);
  } else if (textFiedlProps.onChange) {
    onChange = textFiedlProps.onChange;
  }
  const error = formStateError ? true : false;
  return (
    <TextField
      type="number"
      error={error}
      {...textFiedlProps}
      className={classes.textField}
      onChange={onChange}
    />
  );
}
