import React from 'react';
import './App.css';
import {globalErrorMiddleware} from '../graphql';
import {RestLink} from 'apollo-link-rest';
import {ApolloClient} from 'apollo-client';
import {InMemoryCache} from 'apollo-cache-inmemory';
import {ApolloProvider} from '@shopify/react-graphql';
import {Routes, ThemeProvider, ShopifyApolloProvider} from 'foundation';
import {AppContainer, WithTranslations} from 'components';
import {I18nContext, I18nManager} from '@shopify/react-i18n';

const i18nLocale = 'en';
const i18nManager = new I18nManager({
  fallbackLocale: 'en',
  timezone: 'GMT',
  locale: i18nLocale,
  onError(error) {
    console.error(error);
  },
  currency: 'usd',
});

const link = new RestLink({
  uri: process.env.REACT_APP_API_URL || 'https://www.earthempires.com/rest',
  credentials: 'include',
});

const client = new ApolloClient({
  cache: new InMemoryCache(),
  // link: errorLink.concat(link),
  link: globalErrorMiddleware.concat(link),
});

const App: React.FC = () => {
  return (
    <ApolloProvider client={client}>
      <ShopifyApolloProvider>
        <ThemeProvider>
          <I18nContext.Provider value={i18nManager}>
            <WithTranslations>
              <AppContainer>
                <Routes />
              </AppContainer>
            </WithTranslations>
          </I18nContext.Provider>
        </ThemeProvider>
      </ShopifyApolloProvider>
    </ApolloProvider>
  );
};

export default App;
