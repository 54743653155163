import React, {useMemo} from 'react';
import clsx from 'clsx';
import {makeStyles} from '@material-ui/core/styles';
import MUIAppBar from '@material-ui/core/AppBar';
import Toolbar from '@material-ui/core/Toolbar';
import Button from '@material-ui/core/Button';
import Typography from '@material-ui/core/Typography';
import IconButton from '@material-ui/core/IconButton';
import MenuIcon from '@material-ui/icons/Menu';
import {useI18n} from '@shopify/react-i18n';
import {useAuthContext} from 'foundation';
import Link from '@material-ui/core/Link';
import FullCartIcon from '@material-ui/icons/ShoppingCart';
import {MakeSecondsReadable} from 'utilities';

const useStyles = makeStyles((theme) => ({
  toolbar: {
    paddingRight: 24, // keep right padding when drawer closed
  },
  appBar: {
    zIndex: theme.zIndex.drawer + 1,
    transition: theme.transitions.create(['width', 'margin'], {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
    }),
  },
  menuButton: {
    marginRight: 36,
  },
  menuButtonHidden: {
    display: 'none',
  },
  title: {
    flexGrow: 1,
  },
  CartIcon: {
    marginRight: theme.spacing(2),
  },
}));

interface Props {
  toggleDrawer?: () => void;
  iconPanel: React.ReactNode;
}

export default function AppBar({toggleDrawer, iconPanel}: Props) {
  const [i18n] = useI18n();
  const classes = useStyles();
  const {accountData} = useAuthContext();

  const iconButtonMarkup = useMemo(() => {
    return (
      toggleDrawer && (
        <IconButton
          edge="start"
          color="inherit"
          aria-label="Open drawer"
          onClick={toggleDrawer}
          className={clsx(classes.menuButton)}
        >
          <MenuIcon />
        </IconButton>
      )
    );
  }, [classes.menuButton, toggleDrawer]);

  const patronMarkup = useMemo(() => {
    return (
      accountData.patron && (
        <Typography variant="subtitle2" component="span">
          {' '}
          [Patron]
        </Typography>
      )
    );
  }, [accountData.patron]);

  const premiumMarkup = useMemo(() => {
    const premiumMarkupTime =
      accountData.premium &&
      accountData.premium_until - accountData.time <= 60 * 60 * 24 * 7
        ? ` expires in ${MakeSecondsReadable(
            accountData.premium_until - accountData.time,
          )}`
        : '';
    return (
      accountData.premium && (
        <Typography variant="caption" component="span">
          {' '}
          [Premium{premiumMarkupTime}]
        </Typography>
      )
    );
  }, [accountData.premium, accountData.premium_until, accountData.time]);

  return (
    <MUIAppBar position="absolute" className={classes.appBar}>
      <Toolbar className={classes.toolbar} variant="dense">
        {iconButtonMarkup}
        <Typography
          component="h1"
          variant="h6"
          color="inherit"
          noWrap
          className={classes.title}
        >
          <Link href="/portal" color="inherit" underline="none">
            {i18n.translate('AppBar.title')}
          </Link>
          {patronMarkup}
          {premiumMarkup}
        </Typography>
        <Button
          variant="contained"
          href="https://forms.gle/NG63UnEbsyYqUobu7"
          target="_BLANK"
          size="small"
        >
          Feedback
        </Button>
        <Button variant="text" href="/portal/shop" size="small">
          <FullCartIcon />
          Store
        </Button>
        {iconPanel}
      </Toolbar>
    </MUIAppBar>
  );
}
