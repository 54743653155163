import React from 'react';
import ToggleButton from '@material-ui/lab/ToggleButton';
import ToggleButtonGroup from '@material-ui/lab/ToggleButtonGroup';
import {makeStyles} from '@material-ui/core/styles';

const useStyles = makeStyles((theme) => ({
  fancyCardButtonGroup: {
    backgroundColor: 'transparent',
    flexWrap: 'wrap',
  },
  toggleButton: {
    border: 'none',
    borderRadius: `${theme.shape.borderRadius}px !important`,
    marginRight: theme.spacing(0.5),
    marginBottom: theme.spacing(),
  },
}));

interface Options {
  label: string;
  value: string;
}

interface Props {
  value: any;
  onChange: (
    event: React.MouseEvent<HTMLElement, MouseEvent>,
    value: any,
  ) => void;
  options: Options[];
}

export default function FancyCardToggleButtons({
  value,
  onChange,
  options,
}: Props) {
  const classes = useStyles();

  const optionsMarkup = options.map((option) => {
    return (
      <ToggleButton
        key={option.value}
        className={classes.toggleButton}
        value={option.value}
      >
        {option.label}
      </ToggleButton>
    );
  });
  return (
    <ToggleButtonGroup
      value={value}
      onChange={onChange}
      className={classes.fancyCardButtonGroup}
      size="small"
    >
      {optionsMarkup}
    </ToggleButtonGroup>
  );
}
