import React from 'react';
import clsx from 'clsx';
import Card, {CardProps} from '@material-ui/core/Card';
import {makeStyles} from '@material-ui/core/styles';

interface Props extends CardProps {}

const useStyles = makeStyles((theme) => ({
  fancyCard: {
    overflow: 'visible',
    marginTop: theme.spacing(3),
  },
}));

export default function FancyCard({children, ...props}: Props) {
  const classes = useStyles();
  return (
    <Card {...props} className={clsx(props.className, classes.fancyCard)}>
      {children}
    </Card>
  );
}
