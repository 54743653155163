export {
  apply,
  fetchAt,
  fetchAtOr,
  fetchByPathOr,
  fMap,
  // Fn,
  just,
  mapMaybes,
  // Maybe,
  // Nothing,
  // NOTHING,
  unwrap,
  unwrapOr,
  unwrapWith,
  unwrapWithOr,
} from './Maybe';

export type Nothing = undefined | null;
export type Maybe<T> = T | Nothing;
export const NOTHING: Nothing = undefined;
export type Fn<T, U = T> = (item: T) => U;
