//lazily taken from: https://gist.github.com/cskevint/5817477
export function titleize(sentence: string) {
  if (!sentence.split) return sentence;
  var _titleizeWord = function(string: string) {
      return string.charAt(0).toUpperCase() + string.slice(1).toLowerCase();
    },
    result: string[] = [];
  sentence.split(' ').forEach(function(w) {
    result.push(_titleizeWord(w));
  });
  return result.join(' ');
}
