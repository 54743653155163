import React from 'react';

export function joinWithAnd(arr: any[], andStr = 'and') {
  const filteredArray = arr.filter(Boolean);
  if (filteredArray.length === 0) {
    return '';
  } else if (filteredArray.length === 1) {
    return filteredArray[0];
  }

  const lastElement = filteredArray.pop();
  return `${filteredArray.join(', ')} ${andStr} ${lastElement}`;
}

export function joinNodesWithAnd(arr: any[], andStr = 'and') {
  const filteredArray = arr.filter(Boolean);
  if (filteredArray.length === 0) {
    return '';
  } else if (filteredArray.length === 1) {
    return filteredArray[0];
  }

  const lastElement = filteredArray.pop();
  const markup = filteredArray.map((item, index) => {
    return (
      <span key={'joined_item' + index}>
        {item}
        {index === filteredArray.length - 1 ? null : ', '}
      </span>
    );
  });
  markup.push(
    <span key={'joined_item' + filteredArray.length}>
      {' and '}
      {lastElement}
    </span>,
  );
  return markup;
}
