export function FormatCurrency(num: number, precision = 0) {
  return Intl.NumberFormat('en', {
    style: 'currency',
    currency: 'USD',
    minimumFractionDigits: precision,
  }).format(num);
}

export function FormatCurrencyWithDecimals(num: number) {
  return FormatCurrency(num, 2);
}

export function secondsToHours(num: number) {
  return Intl.NumberFormat('en', {
    minimumFractionDigits: 0,
    maximumFractionDigits: 1,
  }).format(num / 3600);
}

export function secondsToMinutes(num: number) {
  return Intl.NumberFormat('en', {
    minimumFractionDigits: 0,
    maximumFractionDigits: 1,
  }).format(num / 60);
}
const regex = /((^\d*\.?\d*[1-9]+\d*)|(^[1-9]+\d*\.\d*))([kmb])$/i;
const multipliers = {
  k: 1000,
  m: 1000000,
  b: 1000000000,
};

export function quickNumberToFullNumber(numberString: string) {
  const matches = numberString.match(regex);
  // @ts-ignore
  if (matches && multipliers[matches[4]]) {
    // @ts-ignore
    const newNumber = parseFloat(numberString) * multipliers[matches[4]];
    return newNumber;
  }
  return numberString;
}

const pr = new Intl.PluralRules('en-US', {
  type: 'ordinal',
});
const suffixes = new Map([
  ['one', 'st'],
  ['two', 'nd'],
  ['few', 'rd'],
  ['other', 'th'],
]);

export function ordinalNumber(number: number, stringVersion?: string) {
  const rule = pr.select(number);
  const suffix = suffixes.get(rule);
  return `${stringVersion || number}${suffix}`;
}
