import React from 'react';
import clsx from 'clsx';
import CardHeader, {CardHeaderProps} from '@material-ui/core/CardHeader';
import {makeStyles} from '@material-ui/core/styles';

interface Props extends CardHeaderProps {}

const useStyles = makeStyles((theme) => ({
  fancyCardHeader: {
    marginTop: theme.spacing(4) * -1,
    borderRadius: theme.shape.borderRadius,
    backgroundColor: theme.palette.primary.dark,
  },
}));

export default function FancyCardHeader({children, ...props}: Props) {
  const classes = useStyles();
  return (
    <CardHeader
      {...props}
      className={clsx(props.className, classes.fancyCardHeader)}
    />
  );
}
