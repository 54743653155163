import React, {useState, useEffect} from 'react';
import clsx from 'clsx';
import {makeStyles} from '@material-ui/core/styles';
import TextField, {TextFieldProps} from '@material-ui/core/TextField';
import {quickNumberToFullNumber} from 'utilities';

const useStyles = makeStyles((theme) => ({
  textField: {
    width: 80,
    '& ::-webkit-inner-spin-button': {
      '-webkit-appearance': 'none',
      margin: 0,
    },
    '& ::-webkit-outer-spin-button': {
      '-webkit-appearance': 'none',
      margin: 0,
    },
  },
  smallTextField: {
    width: 65,
  },
}));

interface InternalProps {
  formStateOnChange?: any;
  formStateError?: string;
  small?: boolean;
  useMultiplierLetters?: boolean;
  value: string | number;
}

type Props = TextFieldProps & InternalProps;

export default function FormTextField(props: Props) {
  const classes = useStyles();
  const {
    formStateOnChange,
    formStateError,
    small,
    useMultiplierLetters = false,
    value,
    ...textFieldProps
  } = props;
  const [displayValue, setDisplayValue] = useState(value);
  const [justEdited, setJustEdited] = useState(false);

  // since we can inject values, we need to override the display value
  // when it is changed by an event outside this component
  // `justEdited` will helps cut down on processing time
  useEffect(() => {
    if (
      (!useMultiplierLetters || (useMultiplierLetters && !justEdited)) &&
      quickNumberToFullNumber(displayValue.toString()) !== value
    ) {
      setDisplayValue(value);
    }
    setJustEdited(false);
  }, [displayValue, justEdited, useMultiplierLetters, value]);

  let onChange;
  if (typeof formStateOnChange === 'function') {
    onChange = (
      event: React.ChangeEvent<
        HTMLInputElement | HTMLTextAreaElement | HTMLSelectElement
      >,
    ) => {
      // see comment above `useEffect`
      setJustEdited(true);
      setDisplayValue(event.target.value);
      setJustEdited(true);
      if (!useMultiplierLetters) {
        formStateOnChange && formStateOnChange(event.target.value);
      } else {
        const numericValue = quickNumberToFullNumber(event.target.value);
        formStateOnChange && formStateOnChange(numericValue);
      }
    };
  }
  const error = formStateError ? true : false;
  const fieldType = useMultiplierLetters ? 'string' : 'number';
  return (
    <TextField
      type={fieldType}
      error={error}
      {...textFieldProps}
      value={displayValue}
      className={clsx(
        classes.textField,
        small && classes.smallTextField,
        textFieldProps.classes,
      )}
      onChange={onChange}
    />
  );
}
