import React, {useCallback} from 'react';
import clsx from 'clsx';
import Snackbar from '@material-ui/core/Snackbar';
import SnackbarContent from '@material-ui/core/SnackbarContent';
import IconButton from '@material-ui/core/IconButton';
import CloseIcon from '@material-ui/icons/Close';
import {makeStyles} from '@material-ui/core/styles';
import ErrorIcon from '@material-ui/icons/Error';
import blue from '@material-ui/core/colors/blue';

const useStyles = makeStyles((theme) => ({
  info: {
    backgroundColor: blue[300],
    margin: theme.spacing(1),
    flexDirection: 'inherit',
  },
  icon: {
    fontSize: 20,
  },
  iconVariant: {
    opacity: 0.9,
    marginRight: theme.spacing(1),
  },
  message: {
    display: 'flex',
    alignItems: 'center',
  },
}));

interface Props {
  message: string;
  open: boolean;
  onClose: () => void;
}

export default function InfoToast({message, open, onClose}: Props) {
  const classes = useStyles();
  const handleClose = useCallback(
    (event, reason) => {
      if (reason === 'clickaway') {
        return;
      }
      onClose();
    },
    [onClose],
  );

  return (
    <Snackbar
      anchorOrigin={{
        vertical: 'bottom',
        horizontal: 'left',
      }}
      open={open}
      autoHideDuration={6000}
      onClose={handleClose}
      // onExited={cleanupAfterExited}
    >
      <SnackbarContent
        className={classes.info}
        aria-describedby="client-snackbar"
        message={
          <span id="client-snackbar" className={classes.message}>
            <ErrorIcon className={clsx(classes.icon, classes.iconVariant)} />
            {message}
          </span>
        }
        action={[
          <IconButton
            key="close"
            aria-label="Close"
            color="inherit"
            onClick={(ev) => {
              handleClose(ev, 'close');
            }}
          >
            <CloseIcon className={classes.icon} />
          </IconButton>,
        ]}
      />
    </Snackbar>
  );
}
