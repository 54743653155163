export interface LanguageMap {
  en: string;
}

export const SupportedLanguages: LanguageMap = {
  en: 'English',
};

export async function getTranslationDictionary(locale: string) {
  try {
    const dictionary = await import(
      /* webpackChunkName: "Locales-i18n-[request]" */ `locales/${locale}.json`
    );
    return dictionary && dictionary.default;
  } catch (err) {}
}
