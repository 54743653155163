import {onError} from 'apollo-link-error';

export const globalErrorMiddleware = onError(
  ({graphQLErrors, networkError}) => {
    if (graphQLErrors) {
      graphQLErrors.forEach(({message, locations, path}) => {
        switch (message) {
          case 'COUNTRY_NOT_EXIST':
            window.location.href = '/portal/error/no-country';
            break;
          case 'RESET_NOT_STARTED':
            window.location.href = '/portal/error/round-not-started';
            break;
          case 'RESET_ENDED':
            window.location.href = '/portal/error/round-ended';
            break;
          case 'COUNTRY_DEAD':
            window.location.href = '/portal/error/country-dead';
            break;
          case 'VACATION':
            window.location.href = '/portal/error/vacation';
            break;
          case 'TOO_FAST':
            console.log('youre making requests too fast!! Slow down.');
            break;
        }
      });
    }
    if (networkError) console.log(`[Network error]: ${networkError}`);
  },
);
